<template>
     <main>
        <v-pannellum 
            :showFullscreen="true"
            :mouseZoom="false"
            :doubleClickZoom="true"
            :orientation="true"
            :hfov="100"
            :yaw="-90"
            :pitch="0"
            :autoRotate="true"
            :src="equirectangularUrl" 
            :style="style">
        </v-pannellum>
        <SectionLayout style="z-index: 1;">
            <nav class="nav">
                <router-link :to="returnTo">رجوع</router-link>
            </nav>
            <PraiesDetails />
        </SectionLayout>
    </main>
</template>

<script>
import SectionLayout from '../../website-components/website-rehab-alhabeb/section-layout.vue';
import PraiesDetails from '../../website-components/website-rehab-alhabeb/praies-details.vue';
export default {
    name: 'DetailsPage',
    components: {
        SectionLayout,
        PraiesDetails
    },
    data: () => ({
        returnTo: '',
        style: 'position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0;',
        equirectangularUrl: require('../../../../../public/assets/images/website/website-rehab-alhabeb/background/black-bg.jpg')
    }),
    beforeCreate () {
        window.location.replace("https://rihab-alhabib.islamic-apps.com");
    },
    beforeRouteEnter () {
        window.location.replace("https://rihab-alhabib.islamic-apps.com");
    },
    created () {
        this.returnTo = this.$route.meta.from == 'atroja' ? '/atroja' : '/'
    }
}
</script>

<style scoped>
main {
    min-height: 100vh;
}
.nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 0 50px 0;
}
.nav a {
    z-index: 99999999;
    cursor: pointer;
    color: #fff;
    font-weight: 700;
    font-size: 22px;
}
</style>