<template>
    <section class="layout">
        <slot />
    </section>
</template>

<script>
export default {
    name: 'SectionLayout'
}
</script>

<style lang="scss" scoped>
@import "../../../../../public/assets/styles/mixins";
.layout {
    padding: 0 100px;
    @include respond(phone) {
        padding: 0 30px;
    }
}
</style>