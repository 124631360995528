<template>
    <section class="row justify-content-center align-items-center pb-5">
        <div class="col-12 col-md-8 col-lg-10">
            <div class="details">
                <div class="row m-0 w-100 flex-column flex-lg-row justify-content-end details__header">
                    <div class="col-12 col-lg-3 pb-4 pb-lg-0 text-center">
                        <p class="details__header-title">العدد الكلي للمشاركين</p>
                    </div>
                    <div class="col-12 col-lg-3 pb-4 pb-lg-0 text-center">
                        <p class="details__header-title">عدد صلواتك</p>
                    </div>
                    <div class="col-12 col-lg-3 pb-4 pb-lg-0 text-center">
                        <p class="details__header-title">العدد الكلي للصلوات</p>
                    </div>
                </div>
                <div v-for="(item, index) in prayersAnalyitcs" :key="index"
                    class="pt-5 details__content flex-column flex-lg-row m-0 justify-content-end row w-100">
                    <div class="col-3 col-12 col-lg-3 pb-4 pb-lg-0 text-center text-lg-left">
                        <p class="details__content-title">{{ item.title }}</p>
                    </div>
                    <div class="col-3 col-12 col-lg-3 pb-4 pb-lg-0 text-center">
                        <p class="details__content-subtitle">
                            {{ item.visitors }}
                        </p>
                    </div>
                    <div class="col-3 col-12 col-lg-3 pb-4 pb-lg-0 text-center">
                        <p class="details__content-subtitle">
                            {{ item.score }}
                        </p>
                    </div>
                    <div class="col-3 col-12 col-lg-3 pb-4 pb-lg-0 text-center">
                        <p class="details__content-subtitle">
                            {{ item.prayers }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'PraiesDetailsComponent',
    computed: {
        ...mapGetters('userStore', [
            'getAnalyitcsData',
            'getUserLoggedin'
        ]),
        prayersAnalyitcs() {
            const list = Object.keys(this.getUserLoggedin.details).flatMap(year => {
                const yearNumber = year.split('_')[1];
                const yearKey = yearNumber > 1442 ? `_${yearNumber}` : '';
                return this.$route?.meta?.from == 'atroja' && yearNumber == 1442 ? [] : [{
                    title: `هـ${yearNumber}`,
                    score: parseInt(this.getUserLoggedin.details[year] || 0).toLocaleString('en-US'),
                    prayers: parseInt(this.getAnalyitcsData[`rehab_al_habeb_prayers${yearKey}`] || 0).toLocaleString('en-US'),
                    visitors: parseInt(this.getAnalyitcsData[`rehab_al_habeb_visitors${yearKey}`] || 0).toLocaleString('en-US'),
                }]
            });
            return list;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../../public/assets/styles/variables";

.details {
    width: 100%;
    padding: 50px 34px;
    border-radius: 40px;
    background: $background-linear;
    backdrop-filter: $backdrop-filter;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;

    &__header {
        &-title {
            color: $white;
            font-size: 20px;
            white-space: nowrap;
        }
    }

    &__content {

        &-title,
        &-subtitle {
            font-size: 28px;
            font-weight: 700;
            white-space: nowrap;
        }

        &-title {
            color: $brown;
        }

        &-subtitle {
            color: $white;
        }
    }
}
</style>